import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import GestionProduits from '../views/biborne/gestionproduits/GestionProduits';
import Mescommandes from '../views/biborne/mescommandes/Mescommandes';
import Mesperformances from '../views/biborne/mesperformances/Mesperformances';
import Mesproduits from '../views/biborne/mesproduits/Mesproduits';
import Mesreductions from '../views/biborne/mesreductions/Mesreductions';
import Zequipement from '../views/biborne/zequipement/Zequipement';

import MarketPlace from '../views/marketplace/MarketPlace';
// import Information from '../redux/notes/Information';

import ProductDashboard from '../views/dashboard/ProductDashboard';
import OptionDashboard from '../views/dashboard/OptionDashboard';
import AccessDenied from '../layouts/full-layout/AccessDenied';
// import NosMarques from '../views/dashboards/accueil-components/NosMarques/NosMarques';
// import Information2 from '../redux/notes/Information2';

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'));
const RealtimeDashboard = lazy(() => import('../views/dashboard/RealtimeDashboard'));
/* ***End Layouts**** */

const Error = lazy(() => import('../views/authentication/Error'));
const Login = lazy(() => import('../views/authentication/Login'));
const ForgotPassword = lazy(() => import('../views/authentication/ForgetPassword'));
const ResetPassword2 = lazy(() => import('../views/authentication/ResetPassword2'));
const Information = lazy(() => import('../redux/notes/Information'));
const Information2 = lazy(() => import('../redux/notes/Information2'));

/* ****Pages***** */
const Accueil = lazy(() => import('../views/dashboards/Accueil'));
const Dashboard1 = lazy(() => import('../views/dashboards/Dashboard1'));
const ZoneChalandise = lazy(() => import('../views/dashboards/ZoneChalandise'));

// Campagne
const DeploiementCarte = lazy(() => import('../views/campagne/DeploiementCarte'));
const CampagneRestos = lazy(() => import('../views/campagne/CampagneRestos'));
const MesCampagnes = lazy(() => import('../views/campagne/MesCampagnes'));
const MesCartes = lazy(() => import('../views/campagne/MesCartes'));
const MesTailles = lazy(() => import('../views/config/MesTaille'));
const MesImprimantes = lazy(() => import('../views/config/MesImprimantes'));
const MesLangues2 = lazy(() => import('../views/config/MesLangues2'));


const FideliteTableaudebord = lazy(() => import('../views/dashboards/FideliteTableaudebord'));
const ConfigCaisse = lazy(() => import('../views/biborne/ConfigCaisse'));
const ConfigBorne = lazy(() => import('../views/biborne/ConfigBorne'));
const ConfigClickandCollect = lazy(() => import('../views/biborne/ConfigClickandCollect'));
const ConfigModule = lazy(() => import('../views/biborne/ConfigModule'));
const ConfigReduction = lazy(() => import('../views/biborne/ConfigReduction'));
const ConfigReductionEdit = lazy(() => import('../views/biborne/ConfigReductionEdit'));
const ConfigCodepromo = lazy(() => import('../views/biborne/ConfigCodepromo'));
const ConfigEtablissement = lazy(() => import('../views/biborne/ConfigEtablissement'));
const Fusionproduits = lazy(() => import('../views/biborne/Fusionproduits'));
const GestionUtilisateurs = lazy(() => import('../views/biborne/GestionUtilisateurs'));
const EditUtilisateur = lazy(() => import('../views/biborne/gestionUtilisateurs-components/EditUtilisateur'));
const Marques = lazy(() => import('../views/biborne/marques/Marques'));
// Mes Client
const ClientsDashboard = lazy(() => import('../views/clients/Dashboard'));
const ClientsContact = lazy(() => import('../views/clients/ExportContacts'));
const ListContacts = lazy(() => import('../views/clients/ListContacts'));
const ClientsContactDetails = lazy(() =>
  import('../views/clients/contact-components/ContactDetails'),
);
const ClientsFidelite = lazy(() => import('../views/clients/Fidelite'));
const CreateListeDiffusion = lazy(() => import('../views/clients/CreateListeDiffusion'));
const CreateEnvoi = lazy(() => import('../views/clients/campagnesSms-component/CreateEnvoi'));
const EditEnvoi = lazy(() => import('../views/clients/campagnesSms-component/EditEnvoi'));
const CampagnesSms = lazy(() => import('../views/clients/CampagnesSms'));
const EditDiffusionList = lazy(() => import('../views/clients/EditDiffusionList'));
const EnvoiSMS = lazy(() => import('../views/clients/campagnesSms-component/mobile-vues/EnvoiSMS'));
const SuccessedPaiment = lazy(() => import('../views/clients/SuccessedPaiment'));

// NEW DASHBOARD WITH REACT BY POBAR

const DashboardMesResto = lazy(() => import('../views/dashboards/DashboardMesResto'));
const DashboardMesProduits = lazy(() => import('../views/dashboards/DashboardMesProduits'));
const DashboardMesProduitsNew = lazy(() => import('../views/dashboards/DashboardMesProduitsNew'));
const DashboardMesProduitsNewV2 = lazy(() => import('../views/dashboards/myProductsDahboard/DashboardMesProduitsNewV2'));
const DashboardMesOptionsNew = lazy(() => import('../views/dashboards/myOptionsDahboard/DashboardMesOptionsNew'));
const DashboardDownloadExcelProd = lazy(() => import('../views/dashboards/DashboardDownloadExcelProd'));
const DashboardDownloadExcelProdV2 = lazy(() => import('../views/dashboards/myFilesDownloadExcelProd/DashboardDownloadExcelProdV2'));
const DashboardCaAnnuel = lazy(() => import('../views/dashboards/DashboardCaAnnuel'));
const NosMarques = lazy(() => import('../views/dashboards/accueil-components/NosMarques/NosMarques'));
const FootCourt = lazy(() => import('../views/dashboards/accueil-components/NosMarques/footCourt/FootCourt'));

const PDFReader = lazy(() => import('../views/dashboards/PDFReader'));

/* ======================== METABASE ==================== */

const Metabase = lazy(() => import('../views/dashboards/accueil-components/Metabase'));
const SuiviQuotidien = lazy(() => import('../views/dashboards/accueil-components/SuiviQuotidien'));
const SuiviFracnhise = lazy(() => import('../views/dashboards/accueil-components/SuiviFracnhise'));
const SuiviRestaurant = lazy(() =>
  import('../views/dashboards/accueil-components/SuiviRestaurant'),
);
const SuiviProduits = lazy(() => import('../views/dashboards/accueil-components/SuiviProduits'));
const SuiviClient = lazy(() => import('../views/dashboards/accueil-components/SuiviClient'));
const SuiviPerformance = lazy(() =>
  import('../views/dashboards/accueil-components/SuiviPerformance'),
);
const DashboardFidelity = lazy(() =>
  import('../views/dashboards/accueil-components/DashboardFidelity'),
);
const SuiviListeCommandes = lazy(() =>
  import('../views/dashboards/accueil-components/SuiviListeCommandes'),
);
const DashboardStats = lazy(() => import('../views/dashboards/accueil-components/DashboardStats'));
const SuiviCnCListCommand = lazy(() =>
  import('../views/dashboards/accueil-components/SuiviCnCListCommand'),
);
const SuiviCnCListUtilisateurs = lazy(() =>
  import('../views/dashboards/accueil-components/SuiviCnCListUtilisateurs'),
);
const BetaMesTendances = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMesTendances'),
);

const BetaMesRestaurants = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMesRestaurants'),
);
const MesAnnulations = lazy(() =>
  import('../views/dashboards/accueil-components/mesAnnulations/MesAnnulations'),
);
const BetaMesProduits = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMesProduits'),
);
const BetaMesOptions = lazy(() => import('../views/dashboards/accueil-components/BetaMesOptions'));
const BetaAccueil = lazy(() => import('../views/dashboards/accueil-components/BetaAccueil'));
const BetaZjour = lazy(() => import('../views/dashboards/accueil-components/BetaZjour'));
const BetaExportComptable = lazy(() =>
  import('../views/dashboards/accueil-components/BetaExportComptable'),
);
const BetaPrintZ = lazy(() => import('../views/dashboards/accueil-components/BetaPrintZ'));
const BetaZcaisse = lazy(() => import('../views/dashboards/accueil-components/BetaZcaisse'));
const BetaMesClients = lazy(() => import('../views/dashboards/accueil-components/BetaMesClients'));
const BetaMonClickncollect = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMonClickncollect'),
);
const BetaMesReductions = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMesReductions'),
);
const BetaComparerRestos = lazy(() =>
  import('../views/dashboards/accueil-components/BetaComparerRestos'),
);
const BetaMesPerformances = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMesPerformances'),
);
const BetaMonCncClients = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMonCncClients'),
);
const BetaMesCommandes = lazy(() =>
  import('../views/dashboards/accueil-components/BetaMesCommandes'),
);
const BetaFideliteGlobale = lazy(() =>
  import('../views/dashboards/accueil-components/BetaFideliteGlobale'),
);
const BetaFideliteHistorique = lazy(() =>
  import('../views/dashboards/accueil-components/BetaFideliteHistorique'),
);
const BetaCodePromo = lazy(() => import('../views/dashboards/accueil-components/BetaCodePromo'));
const BetaFideliteMesClients = lazy(() =>
  import('../views/dashboards/accueil-components/BetaFideliteMesClients'),
);
const BetaCaAnnuel = lazy(() => import('../views/dashboards/accueil-components/BetaCaAnnuel'));
const BetaCaParHeure = lazy(() => import('../views/dashboards/accueil-components/BetaCaParHeure'));
const V2BetaDashboard = lazy(() =>
  import('../views/dashboards/accueil-components/V2BetaDashboard'),
);
const V2BetaCaAnnuel = lazy(() => import('../views/dashboards/accueil-components/V2BetaCaAnnuel'));
const V2BetaCaAnnuelNombreCommandes = lazy(() =>
  import('../views/dashboards/accueil-components/V2BetaCaAnnuelNombreCommandes'),
);
const V2BetaCaAnnuelPanierMoyen = lazy(() =>
  import('../views/dashboards/accueil-components/V2BetaCaAnnuelPanierMoyen'),
);
const V2BetaCaAnnuelWeek = lazy(() =>
  import('../views/dashboards/accueil-components/V2BetaCaAnnuelWeek'),
);

// statistiques (new)

// // chart
const LineChart = lazy(() => import('../views/charts/LineChart'));
const GredientChart = lazy(() => import('../views/charts/GredientChart'));
const DoughnutChart = lazy(() => import('../views/charts/DoughnutChart'));
const AreaChart = lazy(() => import('../views/charts/AreaChart'));
const ColumnChart = lazy(() => import('../views/charts/ColumnChart'));
const CandlestickChart = lazy(() => import('../views/charts/CandlestickChart'));
const RadialbarChart = lazy(() => import('../views/charts/RadialbarChart'));

// // icons
const ReactIcons = lazy(() => import('../views/icons/ReactIcons'));

// // Etablissements
const EtablissementLists = lazy(() => import('../views/apps/etablissements/EtablissementLists'));
const EtablissementEdit = lazy(() => import('../views/apps/etablissements/EtablissementEdit'));
// // Fidelites
const FideliteCustomer = lazy(() => import('../views/apps/fidelites/FideliteCustomer'));

// Reduction
const ReductionLists = lazy(() => import('../views/apps/reduction/ReductionLists'));
const ReductionEdit = lazy(() => import('../views/apps/reduction/ReductionEdit'));
// fb elements in components
const EtabClickandCollectParamAdmin = lazy(() =>
  import('../components/forms/fb-elements/EtabClickandCollectParamAdmin'),
);

// Plan Produit
const PlanProduit = lazy(() => import('../views/biborne/PlanProduit'));

/* ****Routes***** */
const getRouter = (currentUser, token, isMobile, isBlocked) => {
  return [
    {
      path: '/',
      element: <FullLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to={currentUser ? '/RealtimeDashboard' : '/auth/login'} />,
        },
        {
          path: '/stats/metabase',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Metabase />,
        },
        {
          path: '/stats/SuiviQuotidien',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviQuotidien />,
        },
        {
          path: '/stats/SuiviFracnhise',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviFracnhise />,
        },
        {
          path: '/stats/SuiviRestaurant',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviRestaurant />,
        },
        {
          path: '/stats/SuiviProduits',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviProduits />,
        },
        {
          path: '/stats/SuiviClient',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviClient />,
        },
        {
          path: '/stats/SuiviPerformance',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviPerformance />,
        },
        {
          path: '/fidelite/DashboardFidelity',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardFidelity />,
        },
        {
          path: '/stats/SuiviListeCommandes',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviListeCommandes />,
        },
        {
          path: '/stats/DashboardStats',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardStats />,
        },
        {
          path: '/Cncstats/SuiviCnCListCommand',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviCnCListCommand />,
        },
        {
          path: '/Cncstats/SuiviCnCListUtilisateurs',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuiviCnCListUtilisateurs />,
        },
        {
          path: '/Cncstats/BetaMesTendances',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesTendances />,
        },
        {
          path: '/Cncstats/BetaMesRestaurants',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesRestaurants />,
        },
        {
          path: '/Cncstats/MesAnnulations',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <MesAnnulations />,
        },
        {
          path: '/Cncstats/NosMarques',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <NosMarques />,
        },
        {
          path: '/Cncstats/foodCourt',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <FootCourt />,
        },
        {
          path: '/Cncstats/BetaMesProduits',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesProduits />,
        },
        {
          path: '/Cncstats/BetaMesOptions',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesOptions />,
        },

        {
          path: '/Cncstats/BetaAccueil',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaAccueil />,
        },
        {
          path: '/Cncstats/BetaZjour',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaZjour />,
        },
        {
          path: '/Cncstats/BetaExportComptable',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaExportComptable />,
        },
        {
          path: '/Cncstats/BetaPrintZ',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaPrintZ />,
        },
        {
          path: '/Cncstats/BetaZcaisse',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaZcaisse />,
        },
        {
          path: '/Cncstats/BetaMesClients',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesClients />,
        },
        {
          path: '/Cncstats/BetaMonClickncollect',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMonClickncollect />,
        },
        {
          path: '/Cncstats/BetaMesReductions',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesReductions />,
        },
        {
          path: '/Cncstats/BetaComparerRestos',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaComparerRestos />,
        },
        {
          path: '/Cncstats/BetaMesPerformances',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesPerformances />,
        },
        {
          path: '/Cncstats/BetaMonCncClients',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMonCncClients />,
        },
        {
          path: '/Cncstats/BetaMesCommandes',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaMesCommandes />,
        },
        {
          path: '/Cncstats/EtabClickandCollectParamAdmin',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <EtabClickandCollectParamAdmin />,
        },
        {
          path: '/dashbaord/BetaFideliteGlobale',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaFideliteGlobale />,
        },
        {
          path: '/dashbaord/BetaFideliteHistorique',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaFideliteHistorique />,
        },
        {
          path: '/dashbaord/BetaCodePromo',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaCodePromo />,
        },
        {
          path: '/dashbaord/BetaFideliteMesClients',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaFideliteMesClients />,
        },
        {
          path: '/dashbaord/BetaCaAnnuel',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaCaAnnuel />,
        },
        {
          path: '/dashbaord/BetaCaParHeure',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <BetaCaParHeure />,
        },
        {
          path: '/dashbaord/V2BetaDashboard',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <V2BetaDashboard />,
        },
        {
          path: '/dashbaord/V2BetaCaAnnuel',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <V2BetaCaAnnuel />,
        },
        // nouveau realtime dashboard
        {
          path: !isMobile ? 'RealtimeDashboard2' : '/RealtimeDashboard',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <RealtimeDashboard />,
        },
        {
          path: '/ProductDashboard',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ProductDashboard />,
        },
        {
          path: '/OptionDashboard',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <OptionDashboard />,
        },
        {
          path: '/dashbaord/V2BetaCaAnnuelNombreCommandes',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <V2BetaCaAnnuelNombreCommandes />,
        },
        {
          path: '/dashbaord/V2BetaCaAnnuelPanierMoyen',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <V2BetaCaAnnuelPanierMoyen />,
        },
        {
          path: '/dashbaord/V2BetaCaAnnuelWeek',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <V2BetaCaAnnuelWeek />,
        },

        {
          path: '/biborne/configcaisse',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ConfigCaisse />,
        },
        { path: '/biborne/configborne', exact: true, element: <ConfigBorne /> },
        {
          path: '/biborne/mesproduits',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Mesproduits />,
        },
        {
          path: '/biborne/gestionProduits',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <GestionProduits />,
        },
        {
          path: '/biborne/mesreductions',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Mesreductions />,
        },
        {
          path: '/biborne/mesperformances',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Mesperformances />,
        },
        {
          path: '/biborne/zequipement',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Zequipement />,
        },
        {
          path: '/biborne/mescommandes',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Mescommandes />,
        },
        {
          path: '/biborne/configclickandcollect',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ConfigClickandCollect />,
        },
        {
          path: '/biborne/configmodule',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ConfigModule />,
        },
        {
          path: '/biborne/configreduction',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ConfigReduction />,
        },
        {
          path: '/biborne/editreduction/:idRed',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ConfigReductionEdit />,
        },
        {
          path: '/biborne/configcodepromo',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ConfigCodepromo />,
        },
        {
          path: '/biborne/fusionproduits',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Fusionproduits />,
        },
        {
          path: '/biborne/GestionUtilisateurs',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <GestionUtilisateurs />,
        },
        {
          path: '/biborne/EditUtilisateur/:id',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <EditUtilisateur />,
        },
        {
          path: '/biborne/PlanProduit',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <PlanProduit />,
        },
        {
          path: '/biborne/GestionMarques',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Marques />,
        },
        {
          path: '/biborne/configetablissement/:idetab/:restoId',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ConfigEtablissement />,
        },
        {
          path: '/reductions/lists',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ReductionLists />,
        },
        {
          path: '/reductions/edit',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ReductionEdit />,
        },
        {
          path: '/dashboards/accueil',
          element: !currentUser ? <Navigate to="/auth/login" /> : <Accueil />,
        },
        {
          path: '/dashboards/dashboard1',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Dashboard1 />,
        },
        {
          path: '/mesClients/ZoneChalandise',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ZoneChalandise />,
        },
        {
          path: '/fidelite/tableaudebord',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <FideliteTableaudebord />,
        },
        // Campagne
        {
          path: '/campagne/MesCartes',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <MesCampagnes />,
        },
        {
          path: '/campagne/MesProduits',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <MesCartes />,
        },
        {
          path: '/campagne/DeploiementCarte',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DeploiementCarte />,
        },
        {
          path: '/campagne/CampagneRestos',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <CampagneRestos />,
        },
        {
          path: '/config/mesTailles',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <MesTailles />,
        },
        {
          path: '/config/mesImprimantes',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <MesImprimantes />,
        },
        {
          path: '/config/mesLangues',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <MesLangues2 />,
        },
        
        // NEW DASHBOARD WITH REACT
        {
          path: '/v2/DashboardMesResto',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardMesResto />,
        },
        {
          path: '/v2/DashboardMesProduits',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardMesProduits />,
        },
        {
          path: '/v2/DashboardMesProduitsNew',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardMesProduitsNew />,
        },
        {
          path: '/v2/DashboardMesProduitsNewV2',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardMesProduitsNewV2 />,
        },
        {
          path: '/v2/DashboardMesOptionsNew',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardMesOptionsNew />,
        },
        {
          path: '/v2/DashboardDownloadExcelProd',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardDownloadExcelProd />,
        },
        {
          path: '/v2/DashboardDownloadExcelProdV2',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardDownloadExcelProdV2 />,
        },
        {
          path: '/v2/DashboardCaAnnuel',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <DashboardCaAnnuel />,
        },
        {
          path: '/v2/PDFReader',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <PDFReader />,
        },
        // mes Clients

        {
          path: '/mesClients/dashboard',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <ClientsDashboard />,
        },
        {
          path: '/mesClients/ExportContacts',
          element: !currentUser ? <Navigate to="/auth/login" /> : <ClientsContact />,
        },
        {
          path: '/mesClients/ListContacts',
          element: !currentUser ? <Navigate to="/auth/login" /> : <ListContacts />,
        },
        {
          path: '/mesClients/contactDetails/:phone&:franchise_uuid&:id',
          element: !currentUser ? <Navigate to="/auth/login" /> : <ClientsContactDetails />,
        },
        {
          path: '/mesClients/fidelite',
          element: !currentUser ? <Navigate to="/auth/login" /> : <ClientsFidelite />,
        },
        {
          path: '/mesClients/CreateListeDiffusion',
          element: !currentUser ? <Navigate to="/auth/login" /> : <CreateListeDiffusion />,
        },
        {
          path: '/mesClients/CreateEnvoi',
          element: !currentUser ? <Navigate to="/auth/login" /> : <CreateEnvoi />,
        },
        {
          path: '/mesClients/EditEnvoi/:id',
          element: !currentUser ? <Navigate to="/auth/login" /> : <EditEnvoi />,
        },
        {
          path: '/mesClients/EditDiffusionList/:id',
          element: !currentUser ? <Navigate to="/auth/login" /> : <EditDiffusionList />,
        },
        {
          path: '/mesClients/EnvoiSMS',
          element: !currentUser ? <Navigate to="/auth/login" /> : <EnvoiSMS />,
        },
        {
          path: '/mesClients/successPaiment/:idRecharge',
          element: !currentUser ? <Navigate to="/auth/login" /> : <SuccessedPaiment />,
        },
        {
          path: '/mesClients/CampagnesSms',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <CampagnesSms />,
        },
        //
        {
          path: '/marketplace',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <MarketPlace />,
        },
        {
          path: '/etablissements/lists',
          exact: true,
          element: !currentUser ? (
            <Navigate to="/auth/login" />
          ) : (currentUser?.roles === 'moderator' && isMobile) ||
            currentUser?.roles !== 'moderator' ? (
            <EtablissementLists />
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: '/etablissements/modifier/:idetab',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <EtablissementEdit />,
        },
        {
          path: '/fidelite/listClients',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <FideliteCustomer />,
        },
        {
          path: '/information',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Information />,
        },
        {
          path: !isMobile ? 'RealtimeDashboard' : '/informationVentulateur',
          exact: true,
          element: !currentUser ? <Navigate to="/auth/login" /> : <Information2 />,
        },

        // to delete
        {
          path: '/charts/line-chart',
          element: !currentUser ? <Navigate to="/auth/login" /> : <LineChart />,
        },
        {
          path: '/charts/gredient-chart',
          element: !currentUser ? <Navigate to="/auth/login" /> : <GredientChart />,
        },
        {
          path: '/charts/doughnut-pie-chart',
          element: !currentUser ? <Navigate to="/auth/login" /> : <DoughnutChart />,
        },
        {
          path: '/charts/area-chart',
          element: !currentUser ? <Navigate to="/auth/login" /> : <AreaChart />,
        },
        {
          path: '/charts/column-chart',
          element: !currentUser ? <Navigate to="/auth/login" /> : <ColumnChart />,
        },
        {
          path: '/charts/candlestick-chart',
          element: !currentUser ? <Navigate to="/auth/login" /> : <CandlestickChart />,
        },
        {
          path: '/charts/radialbar-chart',
          element: !currentUser ? <Navigate to="/auth/login" /> : <RadialbarChart />,
        },
        {
          path: '/react-icons',
          element: !currentUser ? <Navigate to="/auth/login" /> : <ReactIcons />,
        },
        {
          path: '*',
          element: !currentUser ? <Navigate to="/auth/login" /> : <Navigate to="/auth/404" />,
        },
      ],
    },
    {
      path: '/accessDenied',
      element: currentUser && isBlocked ? <AccessDenied /> : <Navigate to="/" />,
    },
    {
      path: '/auth',
      element: <BlankLayout />,
      children: [
        { path: '404', element: <Error /> },
        {
          path: '/login',
          element: currentUser && !token ? <Navigate to="/RealtimeDashboard" /> : <Login />,
        },
        {
          path: '/forgotPassword',
          element: currentUser ? <Navigate to="/RealtimeDashboard" /> : <ForgotPassword />,
        },
        {
          path: '/resetPassword/:franchiseId&:token',
          element: currentUser ? <Navigate to="/RealtimeDashboard" /> : <ResetPassword2 />,
        },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
  ];
};

export default getRouter;