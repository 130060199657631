import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const useInitialElements = () => {
    const { listeLangues } = useSelector((state) => state.listeLangues);
  const initialfamillemenu = {
    removed: false,
    name: '',
    removed_by_admin: false,
    items: [],
    idGlobal: uuidv4(),
    translation: listeLangues.map(el=>(
      {
        langue: el?.code,
        name: '',
      }
    ))
  };
  const initialProduct = {
    display_image_in_kitchen: false,
    price_emp_junior: 0,
    apply_discount: false,
    mixed_product: false,
    has_bar_code: false,
    price_lv_mega: 0,
    end_period: 23,
    path: null,
    additionals: [],
    price_emp_senior: 0,
    alias_product: '',
    available_caisse: false,
    sizes: [],
    price: 0,
    hide_price_in_borne: false,
    options: [],
    best_sale: false,
    price_happy_mega: 0,
    product_loyalty: false,
    price_point: 0,
    printerLabel: -1,
    price_bar_mega: 0,
    printer: -1,
    hidden_ubereat: false,
    price_drive_mega: 0,
    price_sp_mega: 0,
    purchase_price: 0,
    size_mega_enabled: false,
    print_ingredients: false,
    hidden_caisse: false,
    is_menu: false,
    price_bar: 0,
    price_bar_senior: 0,
    free_price: false,
    first_period: 0,
    screens: [],
    display_kitchen: -1,
    ingredients: [],
    additional_sale_atSpot: false,
    price_platform: 9.9,
    price_platform_senior: 0,
    product_ubereat: false,
    cook: false,
    option_free: 0,
    has_timetable: false,
    display_time: false,
    price_mega: 0,
    price_bar_junior: 0,
    hidden_online: false,
    promotions: [],
    removed: false,
    special_option: false,
    moment_product: false,
    additional_sale_take_away: false,
    unavailable_online: false,
    price_emp: 0,
    size_senior_enabled: false,
    top_product: false,
    sub_products: [],
    price_drive: 0,
    price_opening: 0,
    valid_caisse: false,
    price_senior: 0,
    price_terasse_junior: 0,
    price_happy_junior: 0,
    later: false,
    promo_basket: false,
    price_platform_junior: 0,
    sold_out: false,
    price_lv: 0,
    sold_out_temporary: false,
    send_color: false,
    max_line: 0,
    daysOn: [],
    shiftsOn: [],
    additional_sale: false,
    labeled: false,
    weight: 0,
    price_happy: 0,
    price_junior: 0,
    price_happy_senior: 0,
    many_size: false,
    price_sp_junior: 0,
    unit: '',
    name: '',
    price_type: '',
    hidden_borne: false,
    hide_name_in_borne: false,
    price_sp_senior: 0,
    size_junior_enabled: false,
    daily_stock: 0,
    hidden: false,
    price_lv_junior: 0,
    price_emp_mega: 0,
    price_lv_senior: 0,
    price_drive_senior: 0,
    description: '',
    price_sp: 0,
    price_platform_mega: 0,
    price_drive_junior: 0,
    enable_in_opening_mode: false,
    // prices: [],
    number_line: 0,
    price_terasse_senior: 0,
    number_column: 0,
    quantity: 0,
    carte: [],
    different_price: false,
    label: false,
    allSizes: [],
    description_ubereat: '',
    bar_code: '',
    removed_by_admin: false,
    price_terasse: 0,
    price_terasse_mega: 0,
    available_on_the_spot: true,
    available_takeaway: true,
    available_bar: true,
    available_delivery: true,
    available_terasse: true,
    available_happy_hour: true,
    translation: listeLangues.map(el=>(
        {
        langue: el?.code,
        name: '',
        description: '',
        description_ubereat: '',
      }
    )),
    type_image_product_moment:"Carree",
    path_product_moment:""
  };
  const initialCategory = {
    hidden: false,
    apply_discount: false,
    category_ubereat: false,
    display_time: false,
    end_period: 0,
    tags: [],
    products_first: false,
    path: null,
    hidden_online: false,
    hidden_ubereat: false,
    first_period: 0,
    available_caisse: false,
    removed: false,
    parent_id: -1,
    sold_out: false,
    moment_category: false,
    name: '',
    removed_by_admin: false,
    next_available: false,
    hidden_borne: false,
    hide_name_in_borne: false,
    has_sub_categories: false,
    daysOn: [],
    shiftsOn: [],
    has_timetable: false,
    idGlobal: uuidv4(),
    type_image_category_moment:"Carree",
    path_category_moment:"",
    offset_print_kitchen:0,
    translation: listeLangues.map(el=>(
      {
        langue: el?.code,
        name: '',
      }
    ))
  };
  const initialOptionsGroup = {
    alphabetical_order: false,
      prepared: false,
      display_free: false,
      shift_option: 0,
      order_name: false,
      min_items: 0,
      screens: [],
      is_ingredient: false,
      max_items: 0,
      no_printable: false,
      number_click: 1,
      printer: -1,
      has_options: false,
      multiple_category: false,
      supplement_ubereat: false,
      print_black_background: true,
      is_bold: false,
      hidden_online: false,
      hidden_ubereat: false,
      removed: false,
      name: "",
      removed_by_admin: false,
      display_screen_sorti: false,
      hidden_borne: false,
      items: [],
    idGlobal: uuidv4(),
    translation: listeLangues.map(el=>(
      {
        langue: el?.code,
        name: '',
      }
    )),
  };
  const pricingMap = {
    price: 0,
    price_buy: 0,
    price_junior: 0,
    price_mega: 0,
    price_senior: 0,
    price_size1: 0,
    price_size2: 0,
    price_size3: 0,
    price_size4: 0,
    price_size5: 0,
    price_size6: 0,
    price_size7: 0,
    price_unity: 0,
  }
const prices= [
  {
    price_size7: 0,
    id_price: 1,
    price_senior: 0,
    price_junior: 0,
    price_mega: 0,
    type_order: "Sur Place",
    price: 0,
    price_size1: 0,
    price_size2: 0,
    price_size3: 0,
    price_size4: 0,
    price_size5: 0,
    price_size6: 0
  },
  {
    price_size7: 0,
    id_price: 2,
    price_senior: 0,
    price_junior: 0,
    price_mega: 0,
    type_order: "A Emporter",
    price: 0,
    price_size1: 0,
    price_size2: 0,
    price_size3: 0,
    price_size4: 0,
    price_size5: 0,
    price_size6: 0
  },
  {
    price_size7: 0,
    id_price: 3,
    price_senior: 0,
    price_junior: 0,
    price_mega: 0,
    type_order: "En Livraison",
    price: 0,
    price_size1: 0,
    price_size2: 0,
    price_size3: 0,
    price_size4: 0,
    price_size5: 0,
    price_size6: 0
  },
  {
    price_size7: 0,
    id_price: 4,
    price_senior: 0,
    price_junior: 0,
    price_mega: 0,
    type_order: "Drive",
    price: 0,
    price_size1: 0,
    price_size2: 0,
    price_size3: 0,
    price_size4: 0,
    price_size5: 0,
    price_size6: 0
  }
];
  const initialSingleOption = {
    alias_supplement: '',
    color: '',
    cost: 0,
    flame_enabled: false,
    groups_sub_supplement: [],
    hidden_borne: false,
    hidden_in_kitchen: false,
    hidden_online: false,
    hidden_ubereat: false,
    id_product: 0,
    many_size: false,
    many_type: false,
    name: '',
    number_flame: 0,
    path: null,
    ...pricingMap,
    prices,
    tariffData: {
      T1: { ...pricingMap, prices },
      T2: { ...pricingMap, prices },
      T3: { ...pricingMap, prices },
    },
    quantity: 0,
    quantity_stock: 0,
    removed: false,
    removed_by_admin: false,
    screens: [],
    send_color: false,
    sold_out: false,
    supplement_item_ubereat: false,
    unity: null,
    idGlobal: uuidv4(),
    translation: listeLangues.map((el) => ({
      langue: el?.code,
      name: '',
    })),
    cookable: false,
  };
  const initialIngredient = {
    groups_sub_supplement: [],
    screens: [],
    quantity: 0,
    quantity_stock: 0,
    cost: 0,
    unity: null,
    price_buy: 0,
    price_unity: null,
    hidden_online: false,
    prices: [],
    sold_out: false,
    hidden_in_kitchen: false,
    price_size7: 0,
    supplement_item_ubereat: false,
    flame_enabled: false,
    price_senior: 0,
    path: '',
    many_type: false,
    price: 0,
    send_color: false,
    alias_supplement: '',
    number_flame: 0,
    price_junior: 0,
    many_size: false,
    price_mega: 0,
    hidden_ubereat: false,
    id_product: 0,
    removed: false,
    order_item: 0,
    name: '',
    removed_by_admin: false,
    price_size1: 0,
    hidden_borne: false,
    price_size2: 0,
    price_size3: 0,
    price_size4: 0,
    price_size5: 0,
    price_size6: 0,
    hidden: false,
    idGlobal: uuidv4(),
    translation: listeLangues.map(el=>(
      {
        langue: el?.code,
        name: '',
      }
    )),
    cookable:false
  };
  const initialPromotion = {
    name_promotion: '',
    quantity: 0,
    removed: false,
    label_size_product: '',
    size_product: '',
    label_discount: '',
    removed_by_admin: false,
    discount: 0,
    type_order: '',
    description: '',
    text_promo: '',
    name: '',
    idGlobal: uuidv4(),
    translation: listeLangues.map(el=>(
      {
        langue: el?.code,
        name_promotion: '',
        description: '',
        text_promo: '',
        name: '',
      }
    )),
  };
  const initialFamilleOption = {
    min_option: 0,
    number_click: 0,
    name_group: '',
    removed: false,
    has_limit_options: false,
    sub_supplements: [],
    option_free: 0,
    max_option: 0,
    title_group: '',
    idGlobal: uuidv4(),
    translation: listeLangues.map(el=>(
      {
        langue: el?.code,
        name_group: '',
        title_group: '',
      }
    ))
  };
  return {
    initialfamillemenu,
    initialProduct,
    initialCategory,
    initialOptionsGroup,
    initialSingleOption,
    initialIngredient,
    initialPromotion,
    initialFamilleOption,
  };
};

export default useInitialElements;
