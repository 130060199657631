import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import html2pdf from 'html2pdf.js';

import 'moment/locale/fr';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  Paper,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Box,
} from '@mui/material';
import { privateRequest } from '../../../requestMethods';
import { ConvertirMonetaire } from '../../../utility/utilityFunctions';

const DetailsCommande = ({ keenioChoose, orderIdChoose, restoDetails }) => {
  console.log('restoDetails', restoDetails);
  console.log('DetailsCommande', 'restoDetails', restoDetails, keenioChoose, orderIdChoose);
  const [detailsCommande, setDetailsCommande] = useState(null);
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currencyCode = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.code;
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;
  // const [paysFranchhise, setPaysFranchhise] = useState(null);
  // const paysFranchhise = "canada";

  const retrieveOrderDetails = () => {
    privateRequest
      .get(`/api/v1/orders/findOneOrderDetails/${orderIdChoose}&${restoDetails?.siret}&${keenioChoose}`)
      .then((response) => {
        setDetailsCommande(response.data);
        console.log("findOneOrderDetails",response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveOrderDetails();
  }, [restoDetails?.siret, keenioChoose, orderIdChoose]);

  // CMD1704025896631
  // Calculer la somme des sumhtamount
  const sumOfSumtaxamount = detailsCommande?.orderItemsTaxData.reduce(
    (sum, item) => sum + item.sumtaxamount,
    0,
  );
  const sumOfSumhtamount = detailsCommande?.orderItemsTaxData.reduce(
    (sum, item) => sum + item.sumhtamount,
    0,
  );
  const sumOfSumttcamount = detailsCommande?.orderItemsTaxData.reduce(
    (sum, item) => sum + item.sumttcamount,
    0,
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      body {
        margin: 0;
        padding: 0;
      }
      /* Ajoutez ici votre style d'impression personnalisé */
    }
  `,
  });
  const handleDownload = () => {
      const element = document.querySelector('#reportContent');
      const fileName = `${detailsCommande?.orderData?.order_id}.pdf`;
      const options = {
        margin: 10,
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
      html2pdf().set(options).from(element).then(() => {
          if (window.GetFileName && typeof window.GetFileName.postMessage === 'function') {
            window.GetFileName.postMessage(JSON.stringify({ fileName }));
          } else {
            console.log('GetFileName is not available', window.GetFileName);
            // Handle the case when the channel is not available
          }
        })
        .catch((error) => {
          console.error('Error generating PDF:', error);
        }).save()
    };
  console.log('detailsCommande', detailsCommande);
  return (
    <Paper elevation={3} style={{ padding: '16px', textAlign: 'center', lineHeight: '0.4' }}>
      {restoDetails && keenioChoose && orderIdChoose ? (
        <Box>
          <Box id="reportContent" sx={{
            textAlign: 'center',
          }} ref={componentRef}>
            <Box className="restoDetials">
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: '3px' }}>
                {restoDetails?.nom}
              </Typography>
              <Typography variant="h6" sx={{ mb: '3px', fontWeight: 400 }}>
                {`${restoDetails?.adresse},  ${restoDetails?.ville}`}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: '4px' }}>
                {restoDetails?.siret}
              </Typography>
            </Box>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Détails de la commande
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
              <strong>Source:</strong> {detailsCommande?.orderData?.sent_from}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
              <strong>Commande N°:</strong> {detailsCommande?.orderData?.order_id}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
              <strong>Vendeur:</strong> {detailsCommande?.orderData?.user_name}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
              {/* <strong>Date et Heure:</strong> {moment(detailsCommande?.orderData?.paid_at).format('llll')} -{' '} */}
              {moment(detailsCommande?.orderData?.paid_at?.split('Z')[0]).format(
                'ddd DD MMM yyyy HH:mm:ss',
              )}{' '}
              - <strong>{detailsCommande?.orderData?.type}</strong>
            </Typography>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      QTE
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      DESIGNATION
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      PRIX
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      TOTAL
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      TVA
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsCommande?.orderItemsWithOptions?.map((item) => (
                    <React.Fragment key={item.pk_id}>
                      <TableRow>
                        <TableCell sx={{ lineHeight: '0.1' }} align="left">
                          {item.quantity}
                        </TableCell>
                        <TableCell
                          sx={{ lineHeight: item.name.length > 25 ? '1.3' : '0.1' }}
                          align="left"
                        >
                          {item.name}
                        </TableCell>
                        <TableCell sx={{ lineHeight: '0.1' }} align="right">
                          {ConvertirMonetaire(currencyCode, currencySymbol, item.price)}
                        </TableCell>
                        <TableCell align="right">
                          {ConvertirMonetaire(currencyCode, currencySymbol, item.ttc_amount)}
                        </TableCell>
                        <TableCell align="right">{item.tax_rate * 100}%</TableCell>
                      </TableRow>
                      {/* Options */}
                      {item.options.map((option) => (
                        <TableRow key={option?.pk_id} sx={{ backgroundColor: '#fcfcf7' }}>
                          {/* <TableCell sx={{ lineHeight: '0.1' }} colSpan={5} align="left">
                        <Typography sx={{ lineHeight: '0.1' }} variant="caption">{option.quantity}</Typography>
                      </TableCell> */}
                          {/* <TableCell sx={{ lineHeight: '0.1' }} align="left">
                        <Typography sx={{ lineHeight: '0.1' }} variant="caption">{option.quantity}</Typography>
                      </TableCell> */}
                          <TableCell sx={{ lineHeight: '0.1' }} align="left">
                            <Typography sx={{ lineHeight: '0.1' }} variant="caption">
                              {option?.quantity}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ lineHeight: '0.1' }} align="left">
                            <Typography
                              sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                              variant="caption"
                            >
                              {option?.name}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ lineHeight: '0.1' }} colSpan={3} align="left">
                            <Typography
                              sx={{ lineHeight: '0.1', fontStyle: 'italic' }}
                              variant="caption"
                            >
                              {option.amount_total > 0
                                ? option?.amount_total + currencySymbol
                                : '*'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h5" align="left" sx={{ paddingTop: 2 }} gutterBottom>
              Nb. Article : {detailsCommande?.orderItemsWithOptions.length}
            </Typography>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      TVA
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      MT. TVA
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      Base HT
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      Base TTC
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailsCommande?.orderItemsTaxData?.map((item) => (
                    <TableRow key={item.tax_rate}>
                      <TableCell sx={{ lineHeight: '0.3' }} align="left">
                        {item.tax_rate * 100}%
                      </TableCell>
                      <TableCell sx={{ lineHeight: '0.3' }} align="left">
                        {ConvertirMonetaire(currencyCode, currencySymbol, item.sumtaxamount)}
                      </TableCell>
                      <TableCell sx={{ lineHeight: '0.3' }} align="right">
                        {ConvertirMonetaire(currencyCode, currencySymbol, item.sumhtamount)}
                      </TableCell>
                      <TableCell sx={{ lineHeight: '0.3' }} align="right">
                        {ConvertirMonetaire(currencyCode, currencySymbol, item.sumttcamount)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow sx={{ backgroundColor: '#e9ecef' }}>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      Total
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="left">
                      {ConvertirMonetaire(currencyCode, currencySymbol, sumOfSumtaxamount)}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMonetaire(currencyCode, currencySymbol, sumOfSumhtamount)}
                    </TableCell>
                    <TableCell sx={{ lineHeight: '0.3' }} align="right">
                      {ConvertirMonetaire(currencyCode, currencySymbol, sumOfSumttcamount)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <hr style={{ borderTop: '0px dashed #e9ecef', margin: '20px 0' }} />

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                Total T.T.C.
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {ConvertirMonetaire(
                  currencyCode,
                  currencySymbol,
                  detailsCommande?.orderData?.total,
                )}
              </Typography>
            </div>

            <hr style={{ borderBottom: '0px dashed #e9ecef', margin: '20px 0' }} />

            {Number(detailsCommande?.orderData?.discount) > 0 && (
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="h6">Réduction</Typography>
                <Typography variant="h6">
                  {detailsCommande?.orderData?.type_discount === 'pourcentage'
                    ? `${detailsCommande?.orderData?.discount} %`
                    : ConvertirMonetaire(
                        currencyCode,
                        currencySymbol,
                        detailsCommande?.orderData?.discount,
                      )}
                </Typography>
              </div>
            )}

            <Typography variant="h6" style={{ marginTop: '16px' }}>
              Mode de paiement ({detailsCommande?.paymentsItemsData.length})
            </Typography>

            <TableContainer>
              <Table>
                <TableBody>
                  {detailsCommande?.paymentsItemsData?.map((item) => (
                    <TableRow>
                      <TableCell sx={{ lineHeight: '0.3' }} align="left">
                        {item.name}
                      </TableCell>
                      <TableCell sx={{ lineHeight: '0.3' }} align="right">
                        {ConvertirMonetaire(currencyCode, currencySymbol, item?.amount)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* 
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Signature : BO413y7jg
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        N* de serie L 3544-560-502-615
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Version logiciel : 9.0.0.11
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Type d'operation : Vente
      </Typography>
      <Typography variant="h6" style={{ marginTop: '16px', lineHeight: '0.3' }}>
        Impression N* 1 - 2 ligne(s)
      </Typography> */}
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap:"10px",
            mt: '16px',
          }}>
          <Button
            variant="contained"
            color="primary"
            // style={{ marginTop: '16px' }}
            onClick={handlePrint}
          >
            Imprimer
          </Button>
          <Button
                      sx={{
                        // display: { md: 'none' },
                      }}
                      variant="contained"
                      onClick={handleDownload}
                    >
                      Télécharger
                    </Button>
          </Box>
          
        </Box>
      ) : (
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '5px', textAlign: 'center', lineHeight: '0.8' }}>
            <p>Cliquez sur une commande pour voir les détails</p>
          </Paper>
        </Grid>
      )}
    </Paper>
  );
};

DetailsCommande.propTypes = {
  keenioChoose: PropTypes.any.isRequired,
  orderIdChoose: PropTypes.any.isRequired,
  restoDetails: PropTypes.any.isRequired,
};

export default DetailsCommande;
