import React, { createContext, useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import authService from '../services/auth.service';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_API_URL_NODEJS_JSONBIBORNE}`);
    
    newSocket.on('connect_error', (error) => {
      console.error('Erreur de connexion WebSocket :', error);
    });

    newSocket.on('export-finished', (messageIo) => {
      console.log("messageIo", messageIo)
      const notificationData=JSON.parse(messageIo)
      setMessage({
        content:'Exportation terminée avec succès !',
        type:"success",
        data:notificationData
      });
    });

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);
const currentUser=authService.getCurrentUser()
  const startExport = () => {
    setMessage({
      content: "Préparation de l'exportation...",
      type: 'loading',
      data: {
        franchiseId: currentUser?.id,
        fileName: currentUser?.roles === 'moderator' ? currentUser?.siret : null,
      },
    });
    // socket.emit('startExport');
  };
  // const exportSuccess = () => {
  //   setMessage({
  //     content:'Exportation terminée avec succès !',
  //     type:"success"
  //   });
  // };
  const exportFailed = (payload) => {
    setMessage(payload);
  };
  return (
    <SocketContext.Provider
      value={{
        socket,
        message,
        setMessage,
        startExport,
        exportFailed,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useSocket = () => useContext(SocketContext);
