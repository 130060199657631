import React, { useState, useEffect } from 'react';
import dateFormat from 'dateformat';
import 'moment/locale/fr';
import frLocale from 'date-fns/locale/fr';
import { Box, Grid, MenuItem, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@material-ui/lab';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
// import AuthService from '../../../services/auth.service';
import PageContainer from '../../../components/container/PageContainer';
import TopCards from './TopCards';

import ListCmdTable from './ListCmdTable';
import ListCmdTableCnC from './ListCmdTableCnC';
import DetailsCommande from './DetailsCommande';
import DetailsCommandeCnC from './DetailsCommandeCnC';
import authService from '../../../services/auth.service';
import useEtablissements from '../../../redux/custom-hooks/useEtablissements';
import { privateReqApi1 } from '../../../requestMethods';
import Icon from '../../../assets/images/icons/fleche.png';

const date = new Date();
const firstDay = dateFormat(
  new Date(date.getFullYear(), date.getMonth(), date.getDate()),
  'yyyy-mm-dd',
);
const lastDay = dateFormat(
  new Date(date.getFullYear(), date.getMonth(), date.getDate()),
  'yyyy-mm-dd',
);

const Mescommandes = () => {
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get('order_id');
  const siret = searchParams.get('siret');
  const keenio = searchParams.get('keenio');
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  const currentUser = authService.getCurrentUser();
  const currencySymbol = currentUserInfo?.countryFranchiseInfo?.currencyInfo?.symbol;

  const [datedebut, setDatedebut] = useState(firstDay);
  const [datefin, setDatefin] = useState(lastDay);
  const { etablissements } = useEtablissements();
  const [clickncollect, setclickncollect] = useState([]);
  const [detailsCnC, setDetailsCnC] = useState(null);
  const initialAdamState = {
    restoId: '0',
    source: 'Caisse & Borne',
  };
  const [adams, setAdams] = useState(initialAdamState);
  const [orderIdChoose, setOrderIdChoose] = useState(null);
  const [keenioChoose, setKeenioChoose] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  useEffect(() => {
    if (siret) {
      setAdams({
        ...adams,
        restoId: etablissements?.find((et) => et?.restos?.siret === siret)?.restos,
      });
    }
  }, [siret, etablissements]);
  useEffect(() => {
    if (keenio) {
      setKeenioChoose(keenio);
    }
  }, [keenio]);
  useEffect(() => {
    if (order_id) {
      setOrderIdChoose(order_id);
    }
  }, [order_id]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'restoId') {
      setAdams({
        ...adams,
        [name]: etablissements?.find((et) => et?.restos?.id === value)?.restos,
      });
    } else {
      setAdams({ ...adams, [name]: value });
    }
  };
  // useEffect(() => {
  //   setKeenioChoose(null)
  //   setOrderIdChoose(null)
  // }, [datedebut, datefin, adams?.restoId?.id])
  const retrieveClickncollect = () => {
    privateReqApi1
      .post(
        `/ordersByLicenceAndDayClickv3/${adams.restoId?.current_licence}&${datedebut}&${datefin}`,
      )
      .then((response) => {
        setclickncollect(response.data);
      })
      .catch((e) => {
        console.log('error while recupering ordersByLicenceAndDayClickv3', e);
      });
  };

  useEffect(() => {
    if (adams.restoId?.current_licence && datedebut && datefin) retrieveClickncollect();
  }, [adams.restoId?.current_licence, datedebut, datefin]);

  useEffect(() => {
    if (currentUser?.roles === 'moderator') {
      setAdams({
        ...adams,
        restoId: {
          ...adams?.restoId,
          current_licence: currentUserInfo?.current_licence,
          ville: currentUserInfo?.ville,
          adresse: currentUserInfo?.adresse,
          nom: currentUserInfo?.nom,
        },
      });
    }
  }, [currentUser?.roles, currentUserInfo?.id]);
  console.log('adams', adams);

  useEffect(() => {
    if (currentUser?.roles === 'moderator') {
      setAdams({
        ...adams,
        restoId: {
          id: currentUser?.id,
          siret: currentUser?.siret,
          current_licence: currentUserInfo?.current_licence,
        },
      });
    }
  }, [currentUser?.roles]);

  const handleClickDetails = (event, siret2, keenio3, orderId) => {
    setKeenioChoose(keenio3);
    setOrderIdChoose(orderId);
    setSelectedId(orderId);
  };

  const handleClicknCollectDetails = (event, cmd) => {
    // console.log("cmd", cmd);
    setDetailsCnC(cmd);
    setSelectedId(cmd?.num_order);
  };
  return (
    <Box>
      <PageContainer title="Mes Commandes" description="Mes Commandes">
        {order_id && siret && keenio ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', md: '80%', lg: '70%', xl: '50%' },
              }}
            >
              <DetailsCommande
                // orderDetails={orderDetails}
                currencySymbol={currencySymbol}
                orderIdChoose={order_id}
                siretChoose={siret}
                keenioChoose={keenio}
              />
            </Box>
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  columnGap: '20px',
                  marginBottom: '20px',
                }}
                className="statsHead"
              >
                {currentUser?.roles !== 'moderator' && (
                  <Grid item xs={4} sm={12} md={3}>
                    <CustomFormLabel htmlFor="restoId">
                      Nom Restaurant ({etablissements?.length !== 0 && etablissements?.length})
                    </CustomFormLabel>
                    <CustomSelect
                      labelId="restoId"
                      id="restoId"
                      name="restoId"
                      value={adams?.restoId?.id || -1}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                    >
                      {/* {currentUser?.roles==="admin" &&<MenuItem sx={{ fontSize: 12 }}>Tous les restaurants</MenuItem>} */}
                      {etablissements.map((etablissement) => (
                        <MenuItem
                          sx={{ fontSize: 12, textTransform: 'uppercase' }}
                          key={etablissement?.restos?.id}
                          value={etablissement?.restos?.id}
                        >
                          {etablissement?.restos?.pseudo}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Grid>
                )}

                <Grid
                  sx={{
                    display: 'none',
                  }}
                  item
                  xs={4}
                  sm={12}
                  md={3}
                >
                  <CustomFormLabel htmlFor="source">Source {adams?.source}</CustomFormLabel>
                  <CustomSelect
                    labelId="source"
                    id="source"
                    name="source"
                    value={adams?.source}
                    onChange={handleInputChange}
                    fullWidth
                    size="small"
                  >
                    <MenuItem value="Caisse & Borne">Caisse & Borne</MenuItem>
                    <MenuItem value="Clickncollect">Clickncollect</MenuItem>
                  </CustomSelect>
                </Grid>

                <Grid item xs={2} sm={12} md={3}>
                  <CustomFormLabel htmlFor="datedebut">Date Debut</CustomFormLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                    <DatePicker
                      value={new Date(datedebut).toISOString().split('Z')[0]}
                      onChange={(newValue2) => {
                        setDatedebut(dateFormat(newValue2, 'yyyy-mm-dd'));
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          size="small"
                          {...params}
                          fullWidth
                          id="datedebut"
                          sx={{
                            '& .MuiSvgIcon-root': {
                              width: '18px',
                              height: '18px',
                            },
                            '& .MuiFormHelperText-root': {
                              display: 'none',
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={2} sm={12} md={3}>
                  <CustomFormLabel htmlFor="datefin">Date Fin</CustomFormLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                    <DatePicker
                      value={new Date(datefin).toISOString().split('Z')[0]}
                      onChange={(newValueDf) => {
                        setDatefin(dateFormat(newValueDf, 'yyyy-mm-dd'));
                      }}
                      renderInput={(params) => (
                        <CustomTextField
                          size="small"
                          {...params}
                          fullWidth
                          id="datefin"
                          sx={{
                            '& .MuiSvgIcon-root': {
                              width: '18px',
                              height: '18px',
                            },
                            '& .MuiFormHelperText-root': {
                              display: 'none',
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Box>
            </Grid>

            {adams?.restoId?.id > 0 ? (
              <>
                <Grid
                  sx={{
                    display: 'none',
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <TopCards
                    datedebut={datedebut}
                    datefin={datefin}
                    idResto={adams?.restoId?.siret}
                    clickncollect={clickncollect}
                    // idResto={adams.restoId.id}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {adams?.source === 'Caisse & Borne' && (
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={7} md={7}>
                        <ListCmdTable
                          datedebut={datedebut}
                          datefin={datefin}
                          idResto={adams?.restoId?.siret}
                          handleClickDetails={handleClickDetails}
                          selectedId={selectedId}
                        />
                      </Grid>
                      <Grid item xs={4} sm={5} md={5}>
                        <DetailsCommande
                          orderIdChoose={orderIdChoose}
                          keenioChoose={keenioChoose}
                          restoDetails={
                            currentUser?.roles === 'moderator'
                              ? {
                                  ...adams?.restoId,
                                  current_licence: currentUserInfo?.current_licence,
                                  ville: currentUserInfo?.ville,
                                  adresse: currentUserInfo?.adresse,
                                  nom: currentUserInfo?.nom,
                                }
                              : {
                                  siret: adams?.restoId?.siret,
                                  adresse: adams?.restoId?.adresse,
                                  nom: adams?.restoId?.nom,
                                  ville: adams?.restoId?.ville,
                                }
                          }
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12}>
                    {adams?.source === 'Clickncollect' && (
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={7} md={7}>
                          <ListCmdTableCnC
                            datedebut={datedebut}
                            datefin={datefin}
                            idResto={adams?.restoId?.siret}
                            selectedId={selectedId}
                            clickncollect={clickncollect}
                            handleClicknCollectDetails={handleClicknCollectDetails}
                          />
                        </Grid>
                        <Grid item xs={4} sm={5} md={5}>
                          <DetailsCommandeCnC
                            currencySymbol={currencySymbol}
                            detailsCnC={detailsCnC}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  style={{
                    padding: '5px',
                    textAlign: 'center',
                    lineHeight: '0.8',
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      height: '500px',
                    }}
                  >
                    <img
                      style={{
                        position: 'absolute',
                        width: '274px',
                        left: 'calc(50% - 68px)',
                        transform: 'translate(-50%, -50%) rotate(-62deg)',
                        top: 'calc(50%)',
                      }}
                      src={Icon}
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: '36px',
                        fontStyle: 'italic',
                        textAlign: 'center',
                        color: '#524B40',
                        position: 'relative',
                      }}
                    >
                      Veuillez Choisir un restaurant.
                    </p>
                  </div>
                </Paper>
              </Grid>
            )}
          </Grid>
        )}
      </PageContainer>
    </Box>
  );
};

export default Mescommandes;
