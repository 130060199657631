import { Box, Button } from '@material-ui/core';
import PropsType from 'prop-types';
import React, { useState } from 'react';
import { Close } from '@material-ui/icons';

import RestauListPopup from './selecteRestau-components/RestauListPopup';
import useEtablissements from '../../../redux/custom-hooks/useEtablissements';
import authService from '../../../services/auth.service';

const SelectRestau = ({ setSelectedRestau, selectedRestau }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { etablissements } = useEtablissements(); //CustomHooks
  const currentUser=authService.getCurrentUser()
  const nbreRestos=etablissements?.filter(resto=>resto.restos.id)?.length
  console.log("nbreRestos", nbreRestos)
  return (
    <Box
    className='SelectRestau'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '8px',
        // mt: { xs: '20px', md: 0 },
      }}
    >
      <Button
        onClick={() => setOpenPopup(true)}
        variant="contained"
        className='topButton'
        sx={{
          width: { xs: '100%', md: 'max-content' },
          backgroundColor: (t) => t.palette.mainColor,
          height: 'max-content',

          boxShadow: {
            md: '0px 4px 6px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
          },
          '&:hover': {
            backgroundColor: (t) => t.palette.DarkMainColor,
          },
        }}
      >
        {selectedRestau
          ? selectedRestau?.restos?.nom
          : `Selectionner  Restaurant ${nbreRestos>0 ? `(${nbreRestos})` : ''}`}
      </Button>
      {selectedRestau && currentUser?.roles!=="guest" && (
        <Close
          onClick={() => setSelectedRestau(null)}
          sx={{
            fontSize: '24px',
            backgroundColor: 'lightgray',
            borderRadius: '50%',
            padding: '4px',
            cursor: 'pointer',
            color: (t) => t.palette.text.textMid,
            '&:hover': {
              color: 'white',
              backgroundColor: (t) => t.palette.mainColor,
            },
          }}
        />
      )}
      <RestauListPopup
        setSelectedRestau={setSelectedRestau}
        selectedRestau={selectedRestau}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      />
    </Box>
  );
};
SelectRestau.propTypes = {
  setSelectedRestau: PropsType.any.isRequired,
  selectedRestau: PropsType.any.isRequired,
};

export default SelectRestau;
