import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useLocation, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import RTL from './layouts/full-layout/customizer/RTL';
import { ModalProvider } from './context/ModalContext';
import ModalManager from './components/modals/ModalManager';
import { SocketProvider } from './context/SocketContext'; // Importer SocketProvider
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import getRouter from './routes/Router';
import './App.css';
import CustomSnackbar from './components/custum-elements/CustomSnackbar';

import App2 from './App2';
import authService from './services/auth.service';

const App = () => {
  const location = useLocation();

  // Accédez aux paramètres de la chaîne de recherche de l'URL
  const searchParams = new URLSearchParams(location.search);
  const currentUser = authService.getCurrentUser();
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  // Récupérez la valeur d'une variable (par exemple, "token")
  const token = searchParams.get('token');
  const routing = useRoutes(
    getRouter(currentUser, token, localStorage?.getItem('guideStatus'), currentUserInfo?.isBlocked),
  );
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <ModalProvider>
      <ModalManager />
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          <CustomSnackbar />
          <SocketProvider>
            <App2 /> {/* Wrapping the app with SocketProvider */}
            {routing}
          </SocketProvider>
        </RTL>
      </ThemeProvider>
    </ModalProvider>
  );
};

export default App;
